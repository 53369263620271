<template>
  <div class="music-player">
    <!-- 搜索区域 -->
    <div class="search-area" v-show="isFirstAreaVisible">
      <div class="search-content">
        <h2 class="title">音乐搜索器</h2>
        <p class="subtitle">多站合一音乐搜索解决方案</p>
        
        <div class="search-card">
          <!-- 平台选择 -->
          <div class="platform-select">
            <el-radio-group v-model="type">
              <el-radio label="nest">网易音乐</el-radio>
              <el-radio label="qq">QQ音乐</el-radio>
              <el-radio label="ku">酷狗音乐</el-radio>
              <el-radio label="jo">joox(国外音乐)</el-radio>
            </el-radio-group>
          </div>
          
          <!-- 搜索输入框 -->
          <div class="search-input">
            <el-input
              v-model="currentSongName"
              placeholder="请输入歌曲名称"
              prefix-icon="el-icon-search"
              @keyup.enter.native="getSong"
            >
              <el-button 
                slot="append" 
                icon="el-icon-search"
                @click="getSong"
                :loading="isLoading"
              >
                搜索音乐
              </el-button>
            </el-input>
          </div>
        </div>

        <!-- 使用提示区域 -->
        <el-card class="tips-section" shadow="hover">
          <div class="tips-list">
            <div class="tips-header">使用提示</div>
            <div class="tip-item">
              <span class="tip-number">1</span>
              <p>请先选择音乐平台，再输入歌曲名称进行搜索</p>
            </div>
            <div class="tip-item">
              <span class="tip-number">2</span>
              <p>支持音乐在线播放，自动匹配歌词，享受完整体验</p>
            </div>
            <div class="tip-item">
              <span class="tip-number">3</span>
              <p>如遇到播放失败，请尝试更换音乐平台</p>
            </div>
          </div>
        </el-card>

        <!-- 友情链接区域 -->
        <el-card class="links-section" shadow="hover">
          <div slot="header" class="section-header">
            <i class="el-icon-connection"></i>
            <span>友情链接</span>
          </div>
          <el-row :gutter="20" class="links-row">
            <el-col :span="8" :xs="24">
              <el-tooltip content="网易云音乐官网" placement="top">
                <div class="link-item" @click="openLink('https://music.163.com')">
                  <i class="el-icon-link"></i>
                  <span>网易云音乐</span>
                </div>
              </el-tooltip>
            </el-col>
            <el-col :span="8" :xs="24">
              <el-tooltip content="QQ音乐官网" placement="top">
                <div class="link-item" @click="openLink('https://y.qq.com')">
                  <i class="el-icon-link"></i>
                  <span>QQ音乐</span>
                </div>
              </el-tooltip>
            </el-col>
            <el-col :span="8" :xs="24">
              <el-tooltip content="酷狗音乐官网" placement="top">
                <div class="link-item" @click="openLink('https://www.kugou.com')">
                  <i class="el-icon-link"></i>
                  <span>酷狗音乐</span>
                </div>
              </el-tooltip>
            </el-col>
          </el-row>
        </el-card>
      </div>
    </div>

    <!-- 播放区域 -->
    <div class="player-container" v-show="!isFirstAreaVisible">
      <!-- 顶部返回按钮 -->
      <div class="top-bar">
        <div class="back-button-container">
          <el-button class="back-button" @click="backToSearch">
            <i class="el-icon-back"></i> 返回搜索
          </el-button>
        </div>
      </div>

      <!-- 播放器主体区域 -->
      <div class="player-wrapper">
        <!-- 背景和遮罩 -->
        <div class="player-background" 
             :style="{ 
               backgroundImage: cover ? `url(${cover})` : 'none',
               backgroundSize: 'cover',
               backgroundPosition: 'center',
               filter: 'blur(20px)',
               opacity: '0.6'
             }">
        </div>
        <div class="player-mask"></div>

        <div class="player-content">
          <!-- 左侧播放列表 -->
          <div class="playlist-container">
            <div class="playlist-header">
              <span>播放列表</span>
              <span>{{ relatedSongs.length }}首歌曲</span>
            </div>
            <div class="playlist">
              <div v-for="(song, index) in relatedSongs" 
                   :key="index"
                   class="playlist-item"
                   :class="{ active: currentSongIndex === index }"
                   @click="playSong(index)">
                <div class="song-info">
                  <span class="song-name">{{ song.name }}</span>
                  <span class="song-artist">{{ song.artist }}</span>
                </div>
              </div>
            </div>
          </div>

          <!-- 右侧播放区域 -->
          <div class="player-main">
            <!-- 音乐信息 -->
            <div class="music-info">
              <div class="album-cover" 
                   :class="{ rotating: isPlaying }"
                   @click="togglePlay">
                <img :src="cover" :alt="songName">
                <div class="play-overlay" :class="{ active: !isPlaying }">
                  <i :class="isPlaying ? 'el-icon-video-pause' : 'el-icon-video-play'"></i>
                </div>
              </div>
              <h2 class="song-title">{{ songName }}</h2>
              <p class="song-artist">{{ artist }}</p>
              <div class="download-section">
                <el-button 
                  type="primary" 
                  icon="el-icon-download"
                  @click="downloadSong"
                  :loading="isDownloading"
                >
                  下载歌曲
                </el-button>
              </div>
            </div>

            <!-- 歌词区域 -->
            <div class="lyrics-container">
              <div class="lyrics-scroll" ref="lyricsScroll">
                <div v-if="parsedLyrics.length" class="lyrics-wrapper">
                  <p v-for="(line, index) in parsedLyrics" 
                     :key="index"
                     :class="{ active: currentLyricIndex === index }"
                     ref="lyricLines"
                     class="lyric-line">
                    {{ line.text }}
                  </p>
                </div>
                <div v-else class="no-lyrics">
                  暂无歌词
                </div>
              </div>
            </div>

            <!-- 播放控制区域 -->
            <div class="player-controls">
              <!-- 进度条 -->
              <div class="progress-bar">
                <div class="time-display">
                  <span class="current-time">{{ formatTime(currentTime) }}</span>
                  <span class="duration-time">{{ formatTime(duration) }}</span>
                </div>
                <el-slider
                  v-model="progress"
                  :show-tooltip="false"
                  @change="onSliderChange"
                  @drag-end="handleDragEnd"
                ></el-slider>
              </div>
              <!-- 控制按钮和音量 -->
              <div class="control-panel">
                <div class="control-buttons">
                  <el-button-group>
                    <el-button circle icon="el-icon-back" @click="playPrev"></el-button>
                    <el-button 
                      circle 
                      :icon="isPlaying ? 'el-icon-video-pause' : 'el-icon-video-play'"
                      @click="togglePlay"
                    ></el-button>
                    <el-button circle icon="el-icon-right" @click="playNext"></el-button>
                  </el-button-group>
                </div>
                <div class="volume-control">
                  <i :class="['el-icon-turn-off', { 'muted': volume === 0 }]"
                     @click="toggleMute"></i>
                  <el-slider 
                    v-model="volume" 
                    @change="onVolumeChange"
                    class="volume-slider"
                    :min="0"
                    :max="100"
                    :step="1"
                  ></el-slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 隐藏的音频播放器 -->
      <audio ref="audioPlayer"
             :src="songUrl"
             @timeupdate="onTimeUpdate"
             @ended="onEnded"
             @loadedmetadata="onLoadedMetadata">
      </audio>
    </div>

    <!-- 下载地址弹窗 -->
    <el-dialog
      title="下载地址"
      :visible.sync="downloadDialogVisible"
      @close="cancelDownload"
      width="400px"
      center
      custom-class="download-dialog">
      <div class="download-content">
        <div class="song-info-dialog">
          <span class="song-name">{{ currentSong?.name }}</span>
          <span class="song-artist">{{ currentSong?.artist }}</span>
        </div>

        <!-- 下载选项 -->
        <div class="download-options" >
          <el-radio-group v-model="selectedFormat">
            <el-radio label="flac">FLAC 格式</el-radio>
            <el-radio label="mp3">MP3 格式</el-radio>
          </el-radio-group>
        </div>

        <div class="download-actions">
          <el-button 
            type="primary" 
            icon="el-icon-download"
            @click="startDownload"
            :loading="isDownloading">
            开始下载
          </el-button>
        </div>

        <div class="download-tips">
          <i class="el-icon-info"></i>
          <span>提示：点击开始下载后将自动开始下载任务</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelDownload">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// @ is an alias to /src

import music from '@/store/music';
import 'aplayer/dist/APlayer.min.css';
import APlayer from 'aplayer';
export default {
  name: 'HomeView',
  data() {
    return {
      isLoading: false,
      type: 'nest',
      songId: '',   // 音乐id
      songName: '', // 音乐名称
      songUrl: '',  // 音乐地址
      artist: '',//歌手
      cover: '',//封面
      lrc: '',//歌词
      num: '',
      link: '',
      currentSong: null,
      selectedIndex: 1,
      aplayer: null, // 播放器
      relatedSongs: [], //音乐列表
      alers: false,
      currentSongName: '',
      isFirstAreaVisible: true,
      isFirstAreaVisible2: '',
      currentSongIndex: 0,
      isPlaying: false,
      currentTime: 0,
      duration: 0,
      progress: 0,
      volume: 76,
      currentLyricIndex: 0,
      parsedLyrics: [],
      isDownloading: false,
      lastVolume: null,
      downloadDialogVisible: false,
      downloadAbortController: null, // 用于取消下载请求
      progressTooltip: null, // 用于存储进度条提示元素
      showDownloadOptions: false, // 是否显示下载选项
      selectedFormat: 'flac', // 用户选择的下载格式
    }
  }, mounted() {
  },
  methods: {
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    //提交表单
    async getSong() {
      if (!this.currentSongName) {
        this.$message.warning('请输入歌曲名称');
        return;
      }
      
      this.isLoading = true;
      
      try {
        let res;
        switch (this.type) {
          case 'nest':
            res = await music.music_nest(this.currentSongName, this.num);
            break;
          case 'qq':
            res = await music.music_QQ(this.currentSongName, this.num);
            break;
          case 'ku':
            res = await music.music_ku(this.currentSongName, this.num);
            break;
          case 'jo':
            res=await music.music_joox(this.currentSongName,this.num);
        }
        
        if (res.data && res.data.data && res.data.data.length > 0) {
          this.relatedSongs = res.data.data;
          this.isFirstAreaVisible = false;
          
          // 确保切换到播放列表后滚动位置在顶部
          this.$nextTick(() => {
            const playlist = this.$el.querySelector('.playlist');
            if (playlist) {
              playlist.scrollTop = 0;
            }
          });
          
          await this.playSong(0);
        } else {
          this.$message.warning('未找到相关歌曲');
        }
      } catch (err) {
        console.error('搜索失败:', err);
        this.$message.error('搜索失败，请重试');
      } finally {
        this.isLoading = false;
      }
    },
    //返回表单
    getfh() {
      this.isFirstAreaVisible = true;
      if (this.aplayer && this.aplayer.pause) {
        this.aplayer.pause();
      }
      // 将实例设置为 null（不会触发清理，但断开了引用）  
      this.aplayer = null;
      this.$nextTick(() => {
         this.aplayer.destroy();
         });

    },
    /* addmusic(song_title, n) {
       if (this.type == 'qq') {
         try {
           music.music_QQ(song_title, n).then(ress => {
             if (ress.data.code != '200') {
               return;
             }
             this.songName = ress.data.data.song_name;
             this.songUrl = ress.data.data.music_url;
             this.cover = ress.data.data.cover;
             this.artist = ress.data.data.song_singer;
             this.lrc = ress.data.data.lyric;
             this.link = ress.data.data.links;
             console.log(this.relatedSongs);
           })
         } catch (error) {
           return;
         } finally {
           this.loading = false;
         }
       }
     },*/
    initAPlayer() {
      if (!this.aplayer) {
        // 播放器配置
        this.aplayer = new APlayer({
          container: document.getElementById('aplayer'),
          listFolded: false,  // Show playlist (not folded)
          listMaxHeight: '300px',  // Max height of playlist
          mini: false,
          autoplay: false,
          theme: '#FADFA3',
          loop: 'all',
          order: 'list',
          preload: 'auto',
          volume: 0.7,
          mutex: true,
          lrcType: 1,
          audio: this.relatedSongs,
          /*
          [
            {
              name: this.songName,
              artist: this.artist,
              url: this.songUrl,
              cover: this.cover,
              lrc: this.lrc,
            },
          ],*/
        });
        this.aplayer.play();
        // 监听列表切换事件
        this.aplayer.on('play', (obj) => {
          // 获取当前播放的歌曲信息
          const index = obj.index;
          console.log(index);
          this.isFirstAreaVisible2 = this.relatedSongs[this.aplayer.list.index];
          this.songName = this.isFirstAreaVisible2.name;
          this.songUrl = this.isFirstAreaVisible2.url;
          this.cover = this.isFirstAreaVisible2.pic;
          this.artist = this.isFirstAreaVisible2.artist;
          this.lrc = this.isFirstAreaVisible2.lrc;
          this.link = this.isFirstAreaVisible2.links;

        });
      }
      this.aplayer.play();
      this.aplayer.on('play', (obj) => {
          // 获取当前播放的歌曲信息
          const index = obj.index;
          console.log(index);
          this.isFirstAreaVisible2 = this.relatedSongs[this.aplayer.list.index];
          this.songName = this.isFirstAreaVisible2.name;
          this.songUrl = this.isFirstAreaVisible2.url;
          this.cover = this.isFirstAreaVisible2.pic;
          this.artist = this.isFirstAreaVisible2.artist;
          this.lrc = this.isFirstAreaVisible2.lrc;
          this.link = this.isFirstAreaVisible2.links;

        });

    },
    /* selectItem(index) {
       this.selectedIndex = index;
       var num = index - 1;
       this.isFirstAreaVisible2 = this.relatedSongs[num];
       this.songName = this.isFirstAreaVisible2.name;
       this.songUrl = this.isFirstAreaVisible2.url;
       this.cover = this.isFirstAreaVisible2.pic;
       this.artist = this.isFirstAreaVisible2.artist;
       this.lrc = this.isFirstAreaVisible2.lrc;
       this.link = this.isFirstAreaVisible2.links;
     },*/
    /*  addToPlaylist(song) {
        // Add song to the APlayer playlist
        this.aplayer.list.add({
          name: song.name,
          url: song.url,
          artist: song.artist,
          cover: 'https://linktoalbumcover.jpg', // Replace with actual cover image URL
          lrc: this.lrc,
        });
      }, */
    downloadFile() {
      this.$refs.downloadLink.click();
    },
    backToSearch() {
      // 重置播放列表滚动位置
      const playlist = this.$el.querySelector('.playlist');
      if (playlist) {
        playlist.scrollTop = 0;
      }
      
      // 停止当前播放
      if (this.isPlaying) {
        this.togglePlay();
      }
      
      // 停止音频播放并清理
      const audio = this.$refs.audioPlayer;
      if (audio) {
        audio.pause();
        audio.currentTime = 0;
        audio.src = '';
      }
      
      // 清理进度条提示
      this.cleanupProgressTooltip();
      
      // 重置相关状态
      this.currentSongName = '';
      this.currentSongIndex = -1;
      this.currentTime = 0;
      this.duration = 0;
      this.progress = 0;
      this.isPlaying = false;
      this.isFirstAreaVisible = true;
      
      // 清空播放相关数据
      this.songUrl = '';
      this.songName = '';
      this.artist = '';
      this.cover = '';
      this.lrc = '';
      this.parsedLyrics = [];
      this.currentLyricIndex = -1;
      this.relatedSongs = [];
      
      // 重置音量
      if (this.lastVolume !== null) {
        this.volume = this.lastVolume;
        this.lastVolume = null;
      }
    },
    async playSong(index) {
      try {
        this.currentSongIndex = index;
        const song = this.relatedSongs[index];
        if (!song) return;

        // 更新歌曲信息
        this.songName = song.name;
        this.artist = song.artist;
        this.cover = song.pic || '';
        this.songUrl = song.url;
        this.lrc = song.lrc;

        // 解析歌词
        this.parsedLyrics = this.parseLrc(song.lrc || '');
        this.currentLyricIndex = -1;

        const audio = this.$refs.audioPlayer;
        if (audio) {
          audio.pause();
          audio.currentTime = 0;
          audio.src = song.url;

          // 等待加载完成后播放
          await new Promise((resolve) => {
            audio.onloadeddata = resolve;
          });

          await audio.play();
          this.isPlaying = true;
        }
      } catch (err) {
        console.error('播放失败:', err);
        this.$message.error('播放失败，请尝试其他歌曲');
      }
    },
    play() {
      if (this.aplayer) {
        this.aplayer.play();
      }
    },
    playPrev() {
      if (this.currentSongIndex > 0) {
        this.currentSongIndex--;
        this.playSong(this.currentSongIndex);
      }
    },
    playNext() {
      if (this.currentSongIndex < this.relatedSongs.length - 1) {
        this.currentSongIndex++;
        this.playSong(this.currentSongIndex);
      }
    },
    togglePlay() {
      const audio = this.$refs.audioPlayer;
      if (!audio) return;

      if (this.isPlaying) {
        audio.pause();
      } else {
        if(this.songUrl=='维护中'){
          this.$message.error('维护中');
          return
        }
        audio.play().then(()=>{
          //成功
          this.isPlaying=true;
        }).catch(err=>{
          console.error('播放失败:', err);
          this.$message.error('播放失败，请重试');
        })
       /* audio.play().then(err => {
          console.error('播放失败:', err);
          this.$message.error('播放失败，请重试');
        });*/
      }
      
      this.isPlaying = !this.isPlaying;
    },
    onTimeUpdate(event) {
      const currentTime = event.target.currentTime * 1000;
      this.currentTime = event.target.currentTime;
      this.progress = (this.currentTime / this.duration) * 100;

      // 只在有歌词时更新
      if (!this.parsedLyrics.length) return;

      // 查找当前应该高亮的歌词
      let currentIndex = this.parsedLyrics.findIndex((lyric, index) => {
        const nextLyric = this.parsedLyrics[index + 1];
        return currentTime >= lyric.time && (!nextLyric || currentTime < nextLyric.time);
      });

      // 只在歌词索引变化时更新
      if (currentIndex !== -1 && currentIndex !== this.currentLyricIndex) {
        this.currentLyricIndex = currentIndex;

        // 确保DOM已更新再滚动
        this.$nextTick(() => {
          this.scrollToActiveLyric();
        });
      }
    },
    onEnded() {
      this.playNext();
    },
    onLoadedMetadata(event) {
      this.duration = event.target.duration;
    },
    onProgressChange(value) {
      this.progress = value;
      if (this.aplayer) {
        this.aplayer.seek(this.progress / 100 * this.duration);
      }
    },
    onVolumeChange(value) {
      this.volume = value;
      const audio = this.$refs.audioPlayer;
      if (audio) {
        audio.volume = this.volume / 100;
      }
    },
    toggleMute() {
      const audio = this.$refs.audioPlayer;
      if (audio) {
        if (audio.volume > 0) {
          this.lastVolume = this.volume;
          this.volume = 0;
          audio.volume = 0;
        } else {
          this.volume = this.lastVolume || 70;
          audio.volume = this.volume / 100;
        }
      }
    },
    parseLrc(lrc) {
      if (!lrc || typeof lrc !== 'string') return [];
      
      // 清理歌词中的特殊字符
      const cleanLrc = lrc.replace(/\\n/g, '\n')
                          .replace(/\r\n/g, '\n')
                          .replace(/\\r/g, '');
      
      const lines = cleanLrc.split('\n');
      const timeExp = /\[(\d{2}):(\d{2})\.(\d{2,3})\]/;
      const result = [];

      lines.forEach(line => {
        // 跳过空行
        if (!line.trim()) return;
        
        const matches = timeExp.exec(line);
        if (!matches) return;
        
        const minutes = parseInt(matches[1]);
        const seconds = parseInt(matches[2]);
        const milliseconds = parseInt(matches[3]);
        const time = minutes * 60 * 1000 + seconds * 1000 + milliseconds;
        // 清理歌词文本
        let text = line.replace(timeExp, '').trim()
                      .replace(/\[.*?\]/g, '') // 移除其他时间标记
                      .replace(/\{.*?\}/g, '') // 移除花括号内容
                      .trim();
        
        if (text) {
          result.push({
            time,
            text
          });
        }
      });

      // 按时间排序
      return result.sort((a, b) => a.time - b.time);
    },
    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = Math.round(seconds % 60);
      return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    },
    // 处理搜索结果
    async handleSearchResult(data) {
      if (!data || !data.data || !data.data.length) {
        throw new Error('搜索结果无效');
      }
      
      this.relatedSongs = data.data;
      this.isFirstAreaVisible = false;
      await this.$nextTick();
      await this.playSong(0);
    },
    // 显示下载弹窗
    async downloadSong() {
      const song = this.relatedSongs[this.currentSongIndex];
      if (!song || !song.url) {
        this.$message.error('暂无可下载的音乐');
        return;
      }
      // 获取文件后缀
      const fileExtension = this.getFileExtension(song.url);

      // 如果是 .mp3，直接触发下载
      if (fileExtension === 'mp3') {
        this.startDownload();
        return;
      }

      // 如果是 .flac，显示下载选项
      this.currentSong = song;
      this.downloadDialogVisible = true;
      this.showDownloadOptions = fileExtension === 'flac'; // 控制是否显示下载选项
      this.selectedFormat = 'flac'; // 默认选择 flac
    },
    // 获取文件后缀的方法
    getFileExtension(url) {
      const extension = url.split('.').pop().toLowerCase();
      return extension;
    },
    // 开始下载任务
    async startDownload() {
    try {
     this.isDownloading = true;
     const song = this.currentSong;
    
    // 根据用户选择的格式生成下载链接
     let downloadUrl = song.url;
     if (this.showDownloadOptions && this.selectedFormat === 'mp3') {
      // 如果是 .flac 文件且用户选择 .mp3，生成 .mp3 下载链接
      downloadUrl = song.url.replace(/\.flac$/i, '.mp3');
     }

    // 创建新的 AbortController
     this.downloadAbortController = new AbortController();
    
    // 添加下载进度提示
     this.$message({
      message: '正在获取下载地址，请稍候...',
      type: 'info',
      duration: 0,
      showClose: true
     });
    
     let blob, /*fileType,*/ url;
    
    // 先获取文件大小
     const headResponse = await fetch(downloadUrl, {
      method: 'HEAD',
      signal: this.downloadAbortController.signal
     });
    
     const contentLength = headResponse.headers.get('content-length');
     const fileSize = parseInt(contentLength);
    
    // 如果文件较大，使用分片下载
     if (fileSize > 10 * 1024 * 1024) { // 10MB
        const CHUNK_SIZE = 2 * 1024 * 1024; // 2MB per chunk
       const chunks = Math.ceil(fileSize / CHUNK_SIZE);
       const chunkPromises = [];
      
       for (let i = 0; i < chunks; i++) {
          const start = i * CHUNK_SIZE;
          const end = Math.min(start + CHUNK_SIZE - 1, fileSize - 1);
        
         chunkPromises.push(
           fetch(downloadUrl, {
             headers: {
               Range: `bytes=${start}-${end}`,
               Accept: 'audio/*'
             },
             signal: this.downloadAbortController.signal
           }).then(res => res.blob())
          );
        }
      
       // 并发下载所有分片
       const blobs = await Promise.all(chunkPromises);
      
       // 合并所有分片
       blob = new Blob(blobs, { type: blobs[0].type });
      // fileType = blob.type.split('/').pop();
       url = window.URL.createObjectURL(blob);
      } else {
      // 小文件直接下载
        const response = await fetch(downloadUrl, {
         signal: this.downloadAbortController.signal,
        headers: {
          'Range': 'bytes=0-',
          'Accept': 'audio/*'
        },
        cache: 'no-cache',
        timeout: 30000
      });
      
      if (!response.ok) {
        throw new Error('下载地址获取失败');
      }
      
      blob = await response.blob();
     // fileType = blob.type.split('/').pop();
      url = window.URL.createObjectURL(blob);
     }

     // 处理文件名中的非法字符
     const sanitizedName = song.name.replace(/[\\/:*?"<>|]/g, '');
     const sanitizedArtist = song.artist.replace(/[\\/:*?"<>|]/g, '');
    
    // 创建下载链接并触发下载
     const link = document.createElement('a');
     link.href = url;
    
     // 处理文件后缀
     let extension = this.selectedFormat || 'mp3';
    
     link.download = `${sanitizedName}-${sanitizedArtist}.${extension}`;
     document.body.appendChild(link);
     link.click();
    
     // 清理
     document.body.removeChild(link);
     window.URL.revokeObjectURL(url);
    
     // 关闭之前的提示
     this.$message.closeAll();
     this.$message.success('开始下载歌曲');
     this.downloadDialogVisible = false;
     } catch (error) {
       if (error.name === 'AbortError') {
         console.log('下载已取消');
         this.$message.closeAll();
         this.$message.info('下载已取消');
          return;
       }
      console.error('下载失败:', error);
      this.$message.closeAll();
      this.$message.error('下载失败，请重试');
     } finally {
       this.isDownloading = false;
       this.downloadAbortController = null;
     }
    },
    // 取消下载
    cancelDownload() {
      if (this.isDownloading && this.downloadAbortController) {
        this.downloadAbortController.abort();
        this.isDownloading = false;
        this.downloadAbortController = null;
      }
      this.downloadDialogVisible = false;
    },
    // 优化滚动方法
    scrollToActiveLyric() {
      const container = this.$refs.lyricsScroll;
      const lyricLines = this.$refs.lyricLines;
      
      if (!container || !lyricLines || !lyricLines[this.currentLyricIndex]) return;
      
      const activeLyric = lyricLines[this.currentLyricIndex];
      const containerHeight = container.clientHeight;
      const lyricHeight = activeLyric.clientHeight;
      
      // 优化滚动位置计算
      const targetScrollTop = Math.max(
        0,
        activeLyric.offsetTop - (containerHeight / 2) + (lyricHeight / 2)
      );
      
      // 使用平滑滚动
      container.scrollTo({
        top: targetScrollTop,
        behavior: 'smooth',
        block: 'center'
      });
    },
    openLink(url) {
      window.open(url, '_blank');
    },
    // 处理滑块变化
    onSliderChange(value) {
      if (!this.$refs.audioPlayer) return;
      const time = (value / 100) * this.duration;
      this.$refs.audioPlayer.currentTime = time;
      this.currentTime = time;
    },
    // 处理拖动结束
    handleDragEnd() {
      // 可以在这里添加拖动结束后的处理逻辑
    },
    // 清理进度条提示
    cleanupProgressTooltip() {
      // 移除所有可能存在的 el-tooltip
      const tooltips = document.querySelectorAll('.el-tooltip__popper');
      tooltips.forEach(tooltip => {
        tooltip.parentNode.removeChild(tooltip);
      });
      
      // 移除所有可能存在的 popper overlay
      const popperOverlays = document.querySelectorAll('.el-popper');
      popperOverlays.forEach(overlay => {
        if (overlay.parentNode) {
          overlay.parentNode.removeChild(overlay);
        }
      });
    },
  },
  watch: {
    // 监听弹窗关闭
    downloadDialogVisible(newVal) {
      if (!newVal && this.isDownloading) {
        this.cancelDownload();
      }
    }
  },
  beforeDestroy() {
    if (this.aplayer && this.aplayer.pause) {
      this.aplayer.pause();
    }
    this.aplayer = null;
    this.aplayer.destroy();
    // 组件销毁时确保取消所有未完成的下载
    if (this.downloadAbortController) {
      this.downloadAbortController.abort();
    }
    this.cleanupProgressTooltip();
  },
  //QQ音乐
  async  QQmues(){
   
  }

}


</script>

<style scoped>
/* 搜索区域样式 */
.search-area {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: #f5f7fa;
}

.search-content {
  width: 100%;
  max-width: 800px;
  background: #fff;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
  text-align: center;
}

.title {
  font-size: 32px;
  color: #333;
  margin-bottom: 8px;
}

.subtitle {
  color: #666;
  margin-bottom: 40px;
}

.search-card {
  background: #f8f9fa;
  padding: 30px;
  border-radius: 8px;
}

.platform-select {
  margin-bottom: 25px;
}

.el-radio-group {
  display: flex;
  justify-content: center;
  gap: 40px;
}

.search-input {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.search-input .el-input-group__append {
  padding: 0 20px;
}

.search-input .el-input__inner {
  height: 40px;
  line-height: 40px;
}

.search-input .el-button {
  height: 40px;
  padding: 0 20px;
}

/* 播放区域样式 */
.player-container {
  min-height: 100vh;
  background: #f5f7fa;
  padding: 20px;
  position: relative;
}

/* 顶部返回按钮样式优化 */
.top-bar {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.back-button-container {
  display: flex;
  justify-content: center;
}

.back-button {
  padding: 10px 25px;
  font-size: 14px;
  border-radius: 20px;
  background: #409EFF;
  color: #fff;
  border: none;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

.back-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(64, 158, 255, 0.3);
}

/* 播放器主体区域样式优化 */
.player-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  background: transparent;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  position: relative;
  backdrop-filter: blur(20px);
  height: calc(100vh - 120px); /* 减去顶部返回按钮和padding的高度 */
}

.player-content {
  display: flex;
  gap: 0;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
  height: 100%;
  position: relative;
  z-index: 2;
}

.playlist-container {
  flex: 0 0 350px;
  background: rgba(255, 255, 255, 0.05);
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.playlist-header {
  padding: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, 0.05);
}

.playlist {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.3) transparent;
}

.playlist::-webkit-scrollbar {
  width: 6px;
}

.playlist::-webkit-scrollbar-track {
  background: transparent;
}

.playlist::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 3px;
  transition: background-color 0.3s;
}

.playlist::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

.playlist-item {
  padding: 12px 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s;
  margin-bottom: 4px;
}

.playlist-item:hover {
  background: rgba(255, 255, 255, 0.1);
}

.playlist-item.active {
  background: rgba(64, 158, 255, 0.2);
  border-left: 3px solid #409EFF;
}

.song-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.song-name {
  font-size: 14px;
  font-weight: 500;
}

.song-artist {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
}

.player-main {
  flex: 1;
  background: transparent;
  border: none;
  padding: 30px;
  color: #fff;
}

/* 音乐信息样式优化 */
.music-info {
  text-align: center;
  margin-bottom: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.album-cover {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.3);
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
  will-change: transform;
  position: relative;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.album-cover:hover {
  transform: scale(1.05) translateZ(0);
}

/* 播放暂停遮罩层 */
.play-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.play-overlay.active {
  opacity: 1;
}

.album-cover:hover .play-overlay {
  opacity: 1;
}

.play-overlay i {
  font-size: 48px;
  color: #fff;
  text-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
}

.play-overlay i:hover {
  transform: scale(1.1);
}

.album-cover.rotating {
  animation: rotate 20s linear infinite;
  animation-fill-mode: both;
}

/* 暂停时停止旋转 */
.album-cover.rotating:hover {
  animation-play-state: paused;
}

@keyframes rotate {
  from { transform: rotate(0deg) translateZ(0); }
  to { transform: rotate(360deg) translateZ(0); }
}

.song-title {
  color: #fff;
  font-size: 24px;
  margin-top: 20px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
}

.song-artist {
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  margin-top: 8px;
  transition: opacity 0.3s ease;
}

/* 鼠标悬停效果 */
.music-info:hover .song-title {
  transform: translateY(-2px);
}

.music-info:hover .song-artist {
  opacity: 0.9;
}

/* 歌词区域样式 */
.lyrics-container {
  height: 300px;
  margin: 30px 0;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  padding: 20px;
  overflow: hidden;
  position: relative;
}

.lyrics-scroll {
  height: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
  scroll-padding: 120px;
  mask-image: linear-gradient(
    to bottom,
    transparent 0%,
    black 15%,
    black 85%,
    transparent 100%
  );
}

.lyrics-scroll::-webkit-scrollbar {
  width: 6px;
}

.lyrics-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(64, 158, 255, 0.3);
  border-radius: 3px;
}

.lyrics-wrapper {
  padding: 100px 0;
  text-align: center;
}

.lyric-line {
  padding: 12px 0;
  transition: all 0.3s ease;
  color: rgba(255, 255, 255, 0.5);
  font-size: 16px;
  line-height: 1.8;
  min-height: 40px;
  letter-spacing: 1px;
}

.lyric-line.active {
  color: #409EFF;
  font-size: 20px;
  font-weight: 500;
  text-shadow: 0 0 10px rgba(64, 158, 255, 0.3);
  transform: scale(1.08);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

/* 添加渐变遮罩效果 */
.lyrics-container::before,
.lyrics-container::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 60px;
  pointer-events: none;
  z-index: 1;
}

.lyrics-container::before {
  top: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
}

.lyrics-container::after {
  bottom: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
}

.no-lyrics {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.5);
  font-size: 16px;
}

/* 播放控制区域样式优化 */
.player-controls {
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  padding: 15px;
  margin-top: 20px;
  overflow: hidden;
}

.progress-bar {
  position: relative;
  z-index: 1;
  padding: 0 15px;
  margin: 10px 0;
}

.time-display {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.8);
}

.current-time,
.duration-time {
  min-width: 45px;
}

.current-time {
  text-align: left;
}

.duration-time {
  text-align: right;
}

.control-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  backdrop-filter: blur(10px);
}

.control-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  padding: 10px 0;
}

.el-button-group {
  display: flex;
  align-items: center;
  gap: 15px;
}

.el-button-group .el-button {
  width: 45px;
  height: 45px;
  background: rgba(255, 255, 255, 0.08);
  border: none;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  overflow: hidden;
}

.el-button-group .el-button:hover {
  background: rgba(255, 255, 255, 0.15);
  transform: scale(1.1);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.el-button-group .el-button:active {
  transform: scale(0.95);
}

/* 播放/暂停按钮特殊样式 */
.el-button-group .el-button:nth-child(2) {
  width: 60px;
  height: 60px;
  font-size: 24px;
  background: linear-gradient(145deg, #409EFF, #36D1DC);
  box-shadow: 0 4px 15px rgba(64, 158, 255, 0.3);
  z-index: 2;
}

.el-button-group .el-button:nth-child(2):hover {
  background: linear-gradient(145deg, #36D1DC, #409EFF);
  box-shadow: 0 6px 20px rgba(64, 158, 255, 0.4);
  transform: scale(1.15);
}

/* 前进后退按钮样式 */
.el-button-group .el-button:first-child,
.el-button-group .el-button:last-child {
  color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(5px);
}

/* 按钮图标样式 */
.el-button-group .el-button i {
  transition: all 0.3s ease;
  position: relative;
  z-index: 2;
}

/* 按钮悬停效果 */
.el-button-group .el-button::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.2) 0%, transparent 70%);
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.5s ease;
  pointer-events: none;
}

.el-button-group .el-button:hover::before {
  transform: translate(-50%, -50%) scale(2);
}

/* 按钮点击波纹效果 */
.el-button-group .el-button::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.3) 0%, transparent 60%);
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.3s ease-out;
  pointer-events: none;
}

.el-button-group .el-button:active::after {
  transform: translate(-50%, -50%) scale(2);
  opacity: 0;
}

/* 音量控制样式优化 */
.volume-control {
  display: flex;
  align-items: center;
  gap: 12px;
  max-width: 150px;
  min-width: 100px;
  flex-shrink: 0;
  background: rgba(255, 255, 255, 0.1);
  padding: 8px 12px;
  border-radius: 20px;
  transition: all 0.3s ease;
}

.volume-control:hover {
  background: rgba(255, 255, 255, 0.15);
}

.volume-control i {
  font-size: 18px;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
}

.volume-control i:hover {
  color: #409EFF;
}

.volume-control i.muted {
  color: rgba(255, 255, 255, 0.5);
}

.volume-slider {
  flex: 1;
  min-width: 60px;
}

/* 自定义滑块样式 */
.volume-slider >>> .el-slider__runway {
  height: 4px;
  background: rgba(255, 255, 255, 0.2);
}

.volume-slider >>> .el-slider__bar {
  height: 4px;
  background: #409EFF;
}

.volume-slider >>> .el-slider__button {
  width: 12px;
  height: 12px;
  border: 2px solid #409EFF;
  background: #fff;
  transition: transform 0.3s ease;
}

.volume-slider >>> .el-slider__button:hover {
  transform: scale(1.2);
}

/* 进度条样式优化 */
.progress-slider >>> .el-slider__runway {
  height: 4px;
  background: rgba(255, 255, 255, 0.2);
}

.progress-slider >>> .el-slider__bar {
  height: 4px;
  background: #409EFF;
}

.progress-slider >>> .el-slider__button {
  width: 12px;
  height: 12px;
  border: 2px solid #409EFF;
  background: #fff;
  transition: transform 0.3s ease;
}

.progress-slider >>> .el-slider__button:hover {
  transform: scale(1.2);
}

/* 时间显示样式 */
.time {
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  min-width: 40px;
  flex-shrink: 0;
  text-align: center;
}

/* 按钮样式优化 */
.el-button-group {
  margin: 0;
  white-space: nowrap;
}

.el-button-group .el-button {
  padding: 8px;
  font-size: 16px;
}

/* 响应式布局优化 */
@media (max-width: 768px) {
  .player-wrapper {
    height: auto;
    min-height: calc(100vh - 80px);
  }

  .player-content {
    flex-direction: column;
  }

  .playlist-container {
    flex: none;
    height: 300px;
    border-right: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .playlist {
    height: calc(300px - 60px); /* 减去播放列表头部高度 */
  }

  .player-main {
    padding: 20px;
  }

  .album-cover {
    width: 150px;
    height: 150px;
  }

  .lyrics-container {
    height: 200px;
    margin: 20px 0;
  }

  .lyrics-wrapper {
    padding: 60px 0;
  }

  .lyric-line {
    font-size: 14px;
    padding: 8px 0;
    min-height: 32px;
  }

  .lyric-line.active {
    font-size: 16px;
  }

  .control-panel {
    flex-direction: column;
    gap: 15px;
  }

  .volume-control {
    width: 100%;
    max-width: none;
    justify-content: center;
  }

  .volume-slider {
    max-width: 200px;
  }

  .player-controls {
    margin-top: 15px;
    padding: 12px;
  }

  .progress-bar {
    margin-bottom: 12px;
  }

  .control-buttons {
    justify-content: center;
  }

  .el-button-group .el-button {
    width: 40px;
    height: 40px;
    font-size: 14px;
  }

  .el-button-group .el-button:nth-child(2) {
    width: 50px;
    height: 50px;
    font-size: 20px;
  }

  .back-button {
    padding: 8px 20px;
    font-size: 13px;
  }

  .download-section .el-button {
    padding: 6px 16px;
    font-size: 12px;
  }

  .el-radio-group {
    gap: 25px;
  }

  .search-content {
    padding: 30px 20px;
    margin: 0 15px;
  }

  .search-input .el-input-group__append {
    padding: 0 15px;
  }

  .search-input .el-button {
    padding: 0 15px;
  }
}

/* 小屏幕手机适配 */
@media (max-width: 480px) {
  .search-content {
    padding: 20px 15px;
  }

  .el-radio-group {
    flex-wrap: wrap;
    gap: 15px;
  }

  .el-radio {
    flex: 0 0 auto;
    font-size: 14px;
  }

  .search-input {
    max-width: 100%;
  }

  .search-input .el-input__inner {
    height: 36px;
    line-height: 36px;
    font-size: 14px;
  }

  .search-input .el-input-group__append {
    padding: 0 10px;
  }

  .search-input .el-button {
    height: 36px;
    padding: 0 10px;
    font-size: 14px;
  }

  .platform-select {
    margin-bottom: 15px;
  }

  .playlist-container {
    height: 250px;
  }

  .playlist {
    height: calc(250px - 50px);
  }

  .album-cover {
    width: 120px;
    height: 120px;
  }

  .song-title {
    font-size: 20px;
  }

  .song-artist {
    font-size: 14px;
  }

  .lyrics-container {
    height: 180px;
    margin: 15px 0;
  }

  .download-section .el-button {
    padding: 8px 20px;
    font-size: 12px;
  }
}

/* 横屏模式适配 */
@media (max-height: 600px) and (orientation: landscape) {
  .player-wrapper {
    height: auto;
  }

  .player-content {
    flex-direction: row;
  }

  .playlist-container {
    height: calc(100vh - 120px);
    flex: 0 0 280px;
  }

  .album-cover {
    width: 100px;
    height: 100px;
  }

  .lyrics-container {
    height: 150px;
  }
}

/* 下载按钮样式优化 */
.download-section {
  margin: 5px 0 15px;
}

.download-section .el-button {
  padding: 8px 20px;
  font-size: 14px;
  border-radius: 20px;
  background: linear-gradient(45deg, #409EFF, #36D1DC);
  border: none;
  transition: all 0.3s ease;
  min-width: 120px;
}

.download-section .el-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(64, 158, 255, 0.3);
}

/* 背景样式优化 */
.player-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  transform: scale(1.2);
  transition: background-image 0.3s ease;
  background-color: rgba(0, 0, 0, 0.8);
}

.player-mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0.6) 50%,
    rgba(0, 0, 0, 0.8) 100%
  );
  backdrop-filter: blur(10px);
}

/* 确保专辑封面图片正常显示 */
.album-cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

/* 新增区域通用样式 */
.tips-section,
.links-section {
  margin-top: 30px;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.tips-list {
  padding: 20px;
}

.tips-header {
  color: #409EFF;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
}

.tip-item {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  padding: 10px;
  border-radius: 8px;
  background: rgba(64, 158, 255, 0.05);
  transition: all 0.3s ease;
  margin-bottom: 10px;
}

.tip-item:hover {
  background: rgba(64, 158, 255, 0.08);
}

.tip-number {
  width: 24px;
  height: 24px;
  background: #409EFF;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  flex-shrink: 0;
  box-shadow: 0 2px 6px rgba(64, 158, 255, 0.2);
}

.tip-item p {
  font-size: 14px;
  color: #606266;
  line-height: 1.6;
  margin: 0;
  padding-top: 2px;
}

/* 友情链接样式 */
.links-row {
  padding: 10px 0;
}

.link-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px;
  border-radius: 8px;
  background: rgba(64, 158, 255, 0.05);
  cursor: pointer;
  transition: all 0.3s ease;
  margin-bottom: 15px;
}

.link-item:hover {
  transform: translateY(-2px);
  background: rgba(64, 158, 255, 0.1);
}

.link-item i {
  font-size: 20px;
  color: #409EFF;
  transition: transform 0.3s ease;
}

.link-item:hover i {
  transform: rotate(45deg);
}

.link-item span {
  color: #303133;
  font-size: 14px;
}

/* 响应式适配 */
@media (max-width: 768px) {
  .tips-section,
  .links-section {
    margin-top: 20px;
  }

  .tips-list {
    padding: 15px;
  }

  .tip-item {
    margin-bottom: 8px;
    padding: 8px;
    gap: 12px;
  }

  .tip-number {
    width: 20px;
    height: 20px;
    font-size: 12px;
  }

  .tip-item p {
    font-size: 13px;
  }

  .link-item {
    padding: 12px;
  }

  .link-item i {
    font-size: 18px;
  }

  .link-item span {
    font-size: 13px;
  }
}

/* 下载弹窗样式 */
.download-dialog {
  border-radius: 12px;
}

.download-content {
  padding: 10px 0;
}

.download-options {
  text-align: center;
  margin-bottom: 20px;
}

.download-options .el-button {
  width: 160px;
  height: 40px;
  font-size: 14px;
}
.download-options .el-radio-group{
  display: flex;
  justify-content: center;
  gap: 20px;
}
.download-actions {
  text-align: center;
  margin-top: 20px;
}
.song-info-dialog {
  margin-bottom: 15px;
  text-align: center;
}

.song-info-dialog .song-name {
  font-size: 16px;
  font-weight: 500;
  color: #303133;
  margin-right: 10px;
}

.song-info-dialog .song-artist {
  font-size: 14px;
  color: #909399;
}

.download-tips {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #909399;
  font-size: 13px;
}

.download-tips i {
  color: #409EFF;
}

/* 响应式适配 */
@media (max-width: 768px) {
  .download-dialog {
    width: 90% !important;
    margin: 0 auto;
  }
  
  .song-info-dialog .song-name {
    font-size: 15px;
  }
  
  .song-info-dialog .song-artist {
    font-size: 13px;
  }
  
  .download-tips {
    font-size: 12px;
  }
}

/* 确保进度条提示不会溢出容器 */
.progress-bar {
  position: relative;
  z-index: 1;
}

/* 覆盖 Element UI 的默认提示样式 */
:deep(.el-slider__button-wrapper) {
  z-index: 2;
}

/* 移动端适配 */
@media (max-width: 768px) {
  .progress-bar {
    padding: 0 10px;
    margin: 8px 0;
  }
  
  .time-display {
    font-size: 11px;
  }
  
  .current-time,
  .duration-time {
    min-width: 40px;
  }
}



</style>