import cfg from '@/store/request';

export default {
    //QQ音乐
    async music_QQ(msg,size){
        try{
            //const response = await cfg.get(`/api/music/qq.php?msg=${encodeURIComponent(msg)}&n=${size}`);
            const response=await cfg.get(`/api/music/qqm3.php?msg=${encodeURIComponent(msg)}&n=${size}`)
            return response;
        }catch(error){
            console.error('音乐接口错误:', error);
            throw new Error('音乐服务暂时不可用');
        }
    },
    //joox
    //国外版
    async music_joox(msg,size){
        try{
            const response=await cfg.get(`/api/music/joox.php?msg=${encodeURIComponent(msg)}&n=${size}`)
            return response;
        }catch(error){
            console.error('音乐接口错误:', error);
            throw new Error('音乐服务暂时不可用');
        }
    },
    //网易音乐
    async music_nest(msg,size){
        try {
            const response = await cfg.get(`/api/music/nest.php?msg=${encodeURIComponent(msg)}&n=${size}`);
            return response;
        } catch (error) {
            console.error('音乐接口错误:', error);
            throw new Error('音乐服务暂时不可用');
        }
    },
    //酷狗音乐
    async music_ku(msg,size){
        try {
            const response = await cfg.get(`/api/music/ku.php?msg=${encodeURIComponent(msg)}&n=${size}`);
            return response;
        } catch (error) {
            console.error('音乐接口错误:', error);
            throw new Error('音乐服务暂时不可用');
        }
    }
}